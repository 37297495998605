<template>
  <b-row>
    <b-col cols="12">
      <b-tabs fill content-class="mt-3" nav-class="w-25" class="tabs-base">
        <b-tab title="Distributor" active>
          <informationDistributor class="mt-5" />
        </b-tab>
        <b-tab title="Suggestions">
          <informationSuggestions class="mt-5" />
        </b-tab>
      </b-tabs>
    </b-col>
  </b-row>
</template>

<script>
import informationDistributor from '@/components/information/distributor'
import informationSuggestions from '@/components/information/suggestions'

export default {
  name: 'Information',
  components: {
    informationDistributor,
    informationSuggestions
  },
  data () {
    return {
    }
  }
}
</script>
