<template>
  <div>
    <list-layout>
      <template slot="search">
        <b-col cols="4">
          <div class="position-relative">
            <b-form-input v-model="search" placeholder="Search" class="pr-12" @keyup.enter="searchName = search" />
            <font-awesome-icon
              :icon="['fas', 'search']"
              fixed-width
              class="input-search text-primary"
              @click="searchName = search"
            />
          </div>
        </b-col>
      </template>
      <template slot="list">
        <div class="table-base">
          <b-table
            hover
            borderless
            :items="sortFeedbackList"
            :current-page="currentPage"
            :per-page="rowPrePage"
            :fields="fields"
            @row-clicked="onSelected"
          >
            <template #head(date)>
              <div class="cursor-pointer" @click="sortList('date')">
                DATE
                <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
              </div>
            </template>
            <template #head(name)>
              <div class="cursor-pointer" @click="sortList('name')">
                NAME
                <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
              </div>
            </template>
            <template #head(title)>
              <div class="cursor-pointer" @click="sortList('title')">
                TITLE
                <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
              </div>
            </template>
          </b-table>
          <div class="d-flex justify-content-between">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="rowPrePage"
              align="fill"
              size="sm"
              class="my-0"
              first-number
              last-number
            />
            <row-count :total-rows="totalRows" :row-pre-page="rowPrePage" :current-page="currentPage" />
          </div>
        </div>
      </template>
    </list-layout>
    <b-modal
      v-model="detail.show"
      size="lg"
      hide-header-close
      centered
      ok-only
    >
      <div v-if="detail.data" class="mx-4 mb-8">
        <b-row>
          <b-col cols="4">
            <b-form-group label="Clinic Name" label-class="text-primary mb-1">
              {{ detail.data.publisher.clinic.clinic_name }}
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Owner" label-class="text-primary mb-1">
              {{ detail.data.publisher.clinic.owner_name }}
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Contact" label-class="text-primary mb-1">
              {{ detail.data.publisher.clinic.contact_name }}
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Address" label-class="text-primary mb-1">
              {{ detail.data.publisher.clinic.clinic_address }}
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Email" label-class="text-primary mb-1">
              {{ detail.data.publisher.clinic.owner_email }}
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Email" label-class="text-primary mb-1">
              {{ detail.data.publisher.clinic.contact_email }}
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Telephone" label-class="text-primary mb-1">
              {{ detail.data.publisher.clinic.clinic_telphone }}
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Date" label-class="text-primary mb-1">
              {{ $dayjs(detail.data.created_at).format('YYYY/MM/DD') }}
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="title" label-class="text-primary mb-1">
              {{ detail.data.title }}
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Content" label-class="text-primary mb-1">
              {{ detail.data.content }}
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import listLayout from '@/components/layouts/partner/listLayout'
import { getFeedbackList } from '@/api/feedback'
import RowCount from '@/components/common/rowCount'

export default {
  name: 'InformationDistributor',
  components: {
    listLayout,
    RowCount
  },
  data () {
    return {
      search: '',

      searchName: '',
      field: 'date',
      ascending: true,

      detail: {
        show: false,
        data: null
      },

      feedbackList: [],
      currentPage: 1,
      rowPrePage: 6
    }
  },
  computed: {
    fields () {
      return ['date', 'name', 'title']
    },
    filterFeedbackList () {
      return this.feedbackList.reduce((list, feedback) => {
        if (this.searchName && feedback.title.indexOf(this.searchName) === -1) return list

        list.push({
          id: feedback.id,
          date: this.$dayjs(feedback.created_at).format('YYYY/MM/DD'),
          name: feedback.publisher.name,
          title: feedback.title
        })
        return list
      }, [])
    },
    sortFeedbackList () {
      return this.filterFeedbackList.slice().sort((a, b) => {
        const dataA = String(a[this.field]).toLowerCase()
        const dataB = String(b[this.field]).toLowerCase()
        if (dataA > dataB) return this.ascending ? 1 : -1
        if (dataA < dataB) return this.ascending ? -1 : 1
        else return 0
      })
    },
    totalRows () {
      return this.sortFeedbackList.length
    },
    totalAutoclave () {
      return this.feedbackList.length
    }
  },
  created () {
    this.getFeedbackList()
  },
  methods: {
    getFeedbackList () {
      getFeedbackList().then(res => {
        this.feedbackList = res.data.data.filter(p => p.publisher.role === 1)
      })
    },
    sortList (field) {
      if (this.field === field) {
        this.ascending = !this.ascending
      } else {
        this.field = field
        this.ascending = true
      }
    },
    onSelected (item) {
      this.detail.data = this.feedbackList.find(p => p.id === item.id)
      this.detail.show = true
    }
  }
}
</script>
